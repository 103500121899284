@import "../../variables.scss";

.rfp-table {
  color: $grey2;
  border: 0.8px solid $grey5;
  border-radius: 4px;
  padding: 1px;
  border-spacing: 0px;
  font-size: 14px;

  thead {
    background-color: $grey6;
  }

  tr {
    th {
      border-bottom: 0.8px solid $grey5;
    }
    td {
      border-bottom: 0.8px solid $grey5;
    }
  }

  tbody {
    :last-child {
      td {
        border-bottom: none;
      }
    }
  }
}
