@import "../../variables.scss";

.tilled-onboarding-form {
  label {
    text-transform: uppercase;
    color: $grey2;
    font-weight: 600;
    font-size: 14px;
  }
}
