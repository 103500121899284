$realWhite: #ffffff;
$black: #232323;
$white: #fafafa;
$yellow: #f6d306;
$red: #ed3054;
$gray: #ededed;
$grey1: #232323;
$grey2: #4e4e4e;
$grey3: #7d7d7d;
$grey5: #d3d3d3;
$grey6: #f6f6f6;
$green: #1cac78;
$greenOpacity15: #1cac7815;
$lightBlue: #e1f0ff;
$pink: #ed3054;
$pinkOpacity15: #ed305415;
$selectedBlue: #dcedff;
$mvmntBlueBg: #f3fbf8;
$successMediumBg: #f3fbf8;
