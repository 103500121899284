@import "./variables.scss";

.react-datepicker-wrapper {
  display: block;
  width: fit-content;
}

.react-datepicker__tab-loop {
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}

.react-datepicker__input-container > input {
  border-width: 1px;
  border-radius: #{$mediumRadius};
  border-color: $gray;
  background-color: #{$white};
  text-align: center;
  height: 40px;
  color: black;
  width: inherit;
  margin: 0;

}


.react-datepicker__input-container > input::placeholder {
  color: #{$gray70}
}