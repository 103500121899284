@import "react-datepicker/dist/react-datepicker.css";
@import "./styles/multiselect.scss";
@import "./styles/daterange.scss";
@import "./styles/phone-input.scss";

body > iframe {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Saira Heading";
  font-weight: 800;
  src: local("Saira"), url("./resources/fonts/Saira.ttf") format("truetype");
}

@font-face {
  font-family: "Saira Body";
  font-weight: 500;
  src: local("Saira"), url("./resources/fonts/Saira.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Regular";
  font-weight: 500;
  src: local("Montserrat Regular"),
    url("./resources/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Bold";
  font-weight: 800;
  src: local("Montserrat Bold"),
    url("./resources/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Thin";
  font-weight: 200;
  src: local("Montserrat Thin"),
    url("./resources/fonts/Montserrat-Thin.ttf") format("truetype");
}
