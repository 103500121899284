// CSS Code taken directly from Chakra UI's input
// CSS selectors are based on `name` property, as we don't have other way to target it
// So we must add the selector here when using the field
input[name="companyPhone"],
input[name="accountsReceivable.phoneNumber"],
input[name="phoneNumber"],
input[name="poPhoneNumber"],
input[name="contactPhoneNumber"],
input[name="companyPhoneNumber"] {
  width: 100%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  color: var(--chakra-colors-black);
  background: inherit;
  background-color: var(--chakra-colors-white);
  text-align: center;
  font-size: var(--chakra-fontSizes-md);
  -webkit-padding-start: var(--chakra-space-4);
  padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: inherit;
}
