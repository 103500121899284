.shipment-history-sidebar-card {
  button {
    background-color: transparent;
    color: transparent;
  }
  &:hover {
    background-color: var(--chakra-colors-selectedBlue);
    button {
      background-color: var(--chakra-colors-yellow);
      color: var(--chakra-colors-black);
    }
  }
}
