@import "../styles/variables.scss";

.dropdown-container {
  --rmsc-radius: #{$mediumRadius};
  --rmsc-bg: #{$white};
  --rmsc-gray: #{$gray70};
  --rmsc-border: #{$gray};
  color: black;
  text-align: center;
  font-family: "Montserrat Regular";
  font-size: 14px;
}

.dropdown-content {
  width: auto !important;
  white-space: nowrap;
}

.small-dropdown {
  * {
    height: 2rem;
  }
  span {
    font-size: 14px;
  }
  .dropdown-content * {
    height: auto;
    .item-renderer {
      align-items: center;
    }
  }
}
