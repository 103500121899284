@import "../../../styles/variables.scss";

.load-board-drawer-field-title {
  color: $gray50;
  font-family: "Saira Body";
  font-size: 11px;
}
.load-board-drawer-field-value {
  color: $gray70;
  font-size: 13px;
}
