@import "./variables.scss";

.subHeadLeft {
    display: flex;
    align-items: center;
}
.totalDisplayedLoadsContainer{
    position: relative;
    display: inline-block;
    color: red;
    margin-right: 34px;
    &:after { 
        content: " ";
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: #d3d3d3;
        right: -12px;
        top: 0;
    }
}

.sortContainer { 
    display: flex;
     & > * {
        flex: 1 0 auto;
     }
}

.searchContainer {
    flex: .5 0 auto;
    max-width: 400px;

}