@import "../../../variables.scss";

.admin-shipper-account-summary-form {
  margin: 20px 0px;
  color: $grey2;

  h4 {
    font-size: 24px;
    color: $grey1;
  }

  hr {
    margin: 40px 0px;
    color: $grey3;
  }

  input {
    border-color: $grey5;
    text-align: left;
    width: 40%;
  }
}
